import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import NewLayout from "../components/new_layout"


const PublicFinancing = ({ data, pageContext }) => {
    const page = data.page.edges[0].node
    const subsidies = data.subsidies.edges
    const public_financing = data.public_financing.frontmatter

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
            />

            <main className="main">
                <div className="main__section main__section--20-80">
                    <div className="container">
                        <div className="hidden-lg">
                            <div className="container__index">
                                {subsidies.map((subsidy, index) => {
                                    return <a href={`#${subsidy.node.id}`}>{subsidy.node.title}</a>
                                })}
                            </div>
                        </div>
                        <div className="container__text">
                            <h1 className="merriweather">{page.title}</h1>
                            <div className="container__text__text">
                                {subsidies.map((subsidy, index) => {
                                    return <div className="text__section" id={subsidy.node.id} key={index}>
                                        <h3>{subsidy.node.title}</h3>
                                        <ReactMarkdown children={subsidy.node.description} rehypePlugins={[rehypeRaw]} />
                                        <div className="list-table">
                                            {subsidy.node.name &&
                                                <div className="list-table__item">
                                                    <h6>{public_financing.name_help}</h6>
                                                    <p>
                                                        {subsidy.node.name}
                                                    </p>
                                                </div>
                                            }
                                            {subsidy.node.beneficiary &&
                                                <div className="list-table__item">
                                                    <h6>{public_financing.beneficiary}</h6>
                                                    <p>
                                                        {subsidy.node.beneficiary}
                                                    </p>
                                                </div>
                                            }
                                            {subsidy.node.duration &&
                                                <div className="list-table__item">
                                                    <h6>{public_financing.duration}</h6>
                                                    <p>
                                                        {subsidy.node.duration}
                                                    </p>
                                                </div>
                                            }
                                            {subsidy.node.date_end &&
                                                <div className="list-table__item">
                                                    <h6>{public_financing.date_end}</h6>
                                                    <p>
                                                        {subsidy.node.date_end}
                                                    </p>
                                                </div>
                                            }
                                            {subsidy.node.budget &&
                                                <div className="list-table__item">
                                                    <h6>{public_financing.budget}</h6>
                                                    <p>
                                                        {subsidy.node.budget}
                                                    </p>
                                                </div>
                                            }
                                            <div className="list-table__item">
                                                <h6>{public_financing.funded_by}</h6>
                                                <p>
                                                    <span>
                                                        {subsidy.node.funded_by}
                                                    </span>
                                                    <div className="list-table__item__logos">
                                                        {subsidy.node.logo_1 &&
                                                            <div className="logos__logo">
                                                                <GatsbyImage alt={subsidy.node.logo_1.alternativeText} image={getImage(subsidy.node.logo_1.localFile)} />
                                                            </div>
                                                        }
                                                        {subsidy.node.logo_2 &&
                                                            <div className="logos__logo">
                                                                <GatsbyImage alt={subsidy.node.logo_2.alternativeText} image={getImage(subsidy.node.logo_2.localFile)} />
                                                            </div>
                                                        }
                                                        {subsidy.node.logo_3 &&
                                                            <div className="logos__logo">
                                                                <GatsbyImage alt={subsidy.node.logo_3.alternativeText} image={getImage(subsidy.node.logo_3.localFile)} />
                                                            </div>
                                                        }
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </NewLayout>
    )
}

export default PublicFinancing

export const federPageQuery = graphql
        `query ($langKey: String) {
        page: allStrapiFondosPublicos (
            filter: { locale: { eq: $langKey } }
        ) {
            edges {
                node {
                    id,
                    seo {
                        title,
                        meta_description
                    }
                    title
                }
            }
        }
        subsidies: allStrapiSubsidies (
            filter: { locale: { eq: $langKey } }
        ){
            edges {
                node {
                    id
                    title
                    name
                    beneficiary
                    duration
                    date_end
                    budget
                    funded_by
                    description
                    logo_1 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 200
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    logo_2 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 200
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    logo_3 {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 200
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
        public_financing: markdownRemark (
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "public-financing"
                }
            }
        ) {
            frontmatter {
                name_help
                beneficiary
                duration
                date_end
                budget
                funded_by
            }
        }
    }
    `
